/* eslint-disable no-console */
import { useMemo } from 'react'
import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import TableHead from './TableHead/TableHead'
import TableRow from './TableRow/TableRow'
import s from './Table.scss'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import { LoadingSkeleton } from 'simple-core-ui'

import mockdata from 'simple-core-ui/data/mock_table_data'

const Table = ({
  rows,
  customRows,
  columns,
  selectRow,
  selectedRows,
  clickRow,
  deleteRow,
  editRow,
  downloadRow,
  customAction,
  hasActions,
  actions,
  alwaysShowActions,
  hideHeader = false,
  noHover = false,
  striped = true,
  sortTable,
  allRowsSelected,
  selectAllRows,
  renderCell,
  theme,
  checkboxSize,
  renderHead,
  fixedHeader,
  tableHeight = '500px',
  showBulkSelectAll,
  avatarHeader,
  borderPosition,
  noOverflow = false,
  className,
  testid,
  disableActionsEventPropagation,
  memoizedRowActions = true,
  customActionsMemoizationDeps = [],
  hasInfinityScroll = false,
  currentPage,
  lastPage,
  updateCurrentPage,
  noRecordsNode = <span>{'(no matching records found)'}</span>,
  hasCgOutlineCheckbox
}) => {
  const isEmpty = rows.length === 0 || columns.length === 0
  const expandable = useMemo(() => rows.some(row => row.expandableContent), [rows])

  const renderTable = () => {
    return (
      <table
        className={cn(s.table, 'table', {
          ['table-striped']: striped
        })}
      >
        {!hideHeader && (
          <TableHead
            columns={columns}
            sortTable={sortTable}
            avatarHeader={avatarHeader}
            hasAvatar={rows.length > 0 && rows.every(({ avatar }) => Boolean(avatar))}
            hasActions={hasActions}
            actions={actions}
            hasBorder={rows.every(({ borderColor }) => Boolean(borderColor))}
            isEmpty={isEmpty}
            allRowsSelected={allRowsSelected}
            alwaysShowActions={alwaysShowActions}
            checkboxSize={checkboxSize}
            showBulkSelectAll={showBulkSelectAll}
            expandable={expandable}
            selectAllRows={
              selectAllRows &&
              (() => {
                selectAllRows()
              })
            }
            hasCgOutlineCheckbox={hasCgOutlineCheckbox}
          />
        )}

        {renderHead && renderHead(columns)}
        <tbody>
          {!isEmpty ? (
            rows.map((row, idx) => {
              const isChecked = selectedRows.has(row.id)
              const showCheckbox = row.canEdit !== false
              return (
                <TableRow
                  noHover={noHover}
                  theme={theme}
                  row={row}
                  columns={columns}
                  clickRow={
                    clickRow &&
                    (() => {
                      clickRow(row)
                    })
                  }
                  checkboxSize={checkboxSize}
                  isChecked={isChecked}
                  selectRow={
                    selectRow &&
                    (() => {
                      selectRow(row)
                    })
                  }
                  avatar={row.avatar}
                  hasActions={hasActions}
                  actions={actions}
                  alwaysShowActions={alwaysShowActions}
                  borderColor={row.borderColor}
                  borderPosition={borderPosition}
                  deleteRow={
                    deleteRow &&
                    (() => {
                      deleteRow(row)
                    })
                  }
                  editRow={
                    editRow &&
                    (() => {
                      editRow(row)
                    })
                  }
                  downloadRow={
                    downloadRow &&
                    (() => {
                      downloadRow(row)
                    })
                  }
                  customAction={customAction}
                  renderCell={renderCell}
                  rowIndex={idx}
                  key={row.id}
                  showCheckbox={showCheckbox}
                  disableActionsEventPropagation={disableActionsEventPropagation}
                  memoizedRowActions={memoizedRowActions}
                  customActionsMemoizationDeps={customActionsMemoizationDeps}
                  hasCgOutlineCheckbox={hasCgOutlineCheckbox}
                />
              )
            })
          ) : customRows ? (
            customRows
          ) : (
            <tr className={s.empty}>
              <td>{noRecordsNode}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <section
      id="scrollableSection"
      className={cn(
        s.container,
        {
          [s.fixedHeader]: fixedHeader,
          [s.noOverflow]: noOverflow
        },
        className
      )}
      style={fixedHeader ? { maxHeight: tableHeight } : {}}
      data-testid={testid}
    >
      {hasInfinityScroll ? (
        <InfiniteScroll
          dataLength={rows.length}
          hasMore={currentPage < lastPage}
          scrollableTarget="scrollableSection"
          loader={
            <>
              <LoadingSkeleton height={20} style={{ marginBottom: 10 }} />
              <LoadingSkeleton height={20} style={{ marginBottom: 10 }} />
              <LoadingSkeleton height={20} style={{ marginBottom: 10 }} />
            </>
          }
          next={() => {
            updateCurrentPage(currentPage + 1)
          }}
        >
          {renderTable()}
        </InfiniteScroll>
      ) : (
        renderTable()
      )}
    </section>
  )
}

Table.defaultProps = {
  rows: [],
  columns: [],
  selectedRows: new Set()
}

registration.register({
  name: 'Table',
  description:
    'The standard component for showing tabular data. It has props that are utilized by more complex container components to allow sorting by column, multi-row selection, and more. See the DataTable component for implementation details.',
  props: [
    {
      name: 'rows',
      type: 'Array<Row> (See type declaration in code sample below)',
      note:
        'The rows to be rendered within the table along with additional props to control rendering logic within the TableRow components. An important note is to be sure that each cell in rows.cells has a matching columnKey in the columns.columnKey. If a matching columnKey is not found for the row an error will be logged in the console with details.'
    },
    {
      name: 'columns',
      type: 'Array<Column> (See type declaration in code sample below)',
      note:
        'The columns to be rendered within the table along with additional props to control rendering logic within the TableHead component.'
    },
    {
      name: 'selectedRows',
      optional: true,
      type: 'Set<RowId> -- (type RowId = number)',
      note:
        'A set of the rows which have been selected. This will only be used when selectRow prop is passed in, which indicates that the table should allow for rows to be selected (via checkbox).'
    },
    {
      name: 'selectRow',
      optional: true,
      type: 'function -- (Row) => void',
      note:
        'The callback which will be invoked when a row is selected via the checkbox. By passing this prop in the rows will render with a checkbox on the far left side. The callback will receive a Row type as an argument. (See type declaration in code sample below)'
    },
    {
      name: 'clickRow',
      optional: true,
      type: 'function -- (Row) => void',
      note:
        'The callback which will be invoked when a row is clicked. It will receive a Row type as an argument. (See type declaration in code sample below)'
    },
    {
      name: 'deleteRow',
      optional: true,
      type: 'function -- (Row) => void',
      note:
        'The callback which will be invoked when a row is deleted via the trashcan. By passing this prop in the rows will render with a trashcan icon on the far right side. In order for the trashcan icon to show up you must also pass in true for the prop hasActions. The callback will receive a Row type as an argument. (See type declaration in code sample below)'
    },
    {
      name: 'editRow',
      optional: true,
      type: 'function -- (Row) => void',
      note:
        'The callback which will be invoked when a row is edited via the pencil. By passing this prop in the rows will render with a pencil icon on the far right side. In order for the pencil icon to show up you must also pass in true for the prop hasActions. The callback will receive a Row type as an argument. (See type declaration in code sample below)'
    },
    {
      name: 'downloadRow',
      optional: true,
      type: 'function -- (Row) => void',
      note:
        'The callback which will be invoked when a row is downloaded via the download icon. This is used for example for files. By passing this prop in the rows will render with a download icon on the far right side. In order for the download icon to show up you must also pass in true for the prop hasActions. The callback will receive a Row type as an argument. (See type declaration in code sample below)'
    },
    {
      name: 'hasActions',
      optional: true,
      type: 'boolean',
      note:
        'A boolean prop which will render extra space on the right side of the table for actions such as showing the attachments icon or the icon to delete.'
    },
    {
      name: 'customActionsMemoizationDeps',
      optional: true,
      type: 'Array<any>',
      note:
        'An array of dependencies to be used when memoizing the custom actions. This is useful if you have a large number of rows and are experiencing performance issues.'
    },
    {
      name: 'memoizedRowActions',
      optional: true,
      type: 'boolean',
      note:
        'A boolean prop which will memoize the row actions. This is useful if you have a large number of rows and are experiencing performance issues.'
    },
    {
      name: 'actions',
      optional: true,
      type: `Array<object/function> --
        {
          icon: Component,
          tooltip: 'String,
          onClick: function -- (event, row) => void,
          disabled: Boolean,
          className: String,
          condition: boolean
        },
        row => ({
          icon: Component,
          tooltip: 'String,
          onClick: function -- (event, row) => void,
          disabled: Boolean,
          className: String,
          condition: boolean
        })
      `,
      note: 'An array of actions that will be rendered on the right side of the table'
    },
    {
      name: 'bulkActions',
      optional: true,
      type: `Array<object/function> --
        {
          icon: Component,
          tooltip: 'String,
          onClick: function -- (event, row) => void,
          disabled: Boolean,
          className: String,
          condition: boolean
        },
        row => ({
          icon: Component,
          tooltip: 'String,
          onClick: function -- (event, row) => void,
          disabled: Boolean,
          className: String,
          condition: boolean
        })
      `,
      note:
        'An array of bulk actions that will be rendered on the top left side of the table. The icons will be visible if at least on row is selected (selectedRows.size !== 0)'
    },
    {
      name: 'sortTable',
      optional: true,
      type: 'function -- (ColumnKey: string, isDesc: boolean) => void',
      note:
        'The callback function which, when provided will determine if the table is sortable and also be invoked when a sortable column header is clicked. The columnKey, the unique name and identifier for the column, will be provided as an argument to the callback along with a boolean specifying if the column sort should be descending or not.'
    },
    {
      name: 'allRowsSelected',
      optional: true,
      type: 'boolean',
      note:
        'A boolean prop which is used to determine if the checkbox in the top left should render as checked or not. Only applicable if selectAllRows is passed in as a prop.'
    },
    {
      name: 'selectAllRows',
      optional: true,
      type: 'function -- () => void',
      note:
        'A callback which will be invoked when the checkbox in the top left of the TableHead is clicked. If this is not provided that checkbox in the top left will not be rendered.'
    },
    {
      name: 'renderCell',
      optional: true,
      type: 'function -- ({rowId: number, columnKey: string, content: React.Node}) => React.Node',
      note:
        'A callback that takes in a Cell and should return a React Node to render. This should only be used custom rendering needs to take place.'
    },
    { name: 'theme', type: 'eb | cg', note: 'The theme to set for the component.' }
  ],
  example: {
    literal: `
/*
rows = [
  {
    id: 1,
    cells: [
      { columnKey: 'invoiceNumber', content: 4128 },
      { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
      { columnKey: 'description', content: 'Adipisicing in architecto illum aliquid natus.' },
      { columnKey: 'total', content: '$6683' },
      { columnKey: 'status', content: 'Rejected' }
    ],
    attachments: false,
    avatar: 'Kurt Rose',
    canDelete: true,
    borderColor: 'red' },
    (...rest of rows)
]

columns = [
  { columnKey: 'invoiceNumber', content: 'Number', isSortable: true },
  { columnKey: 'invoiceDate', content: 'Date', isSortable: true, style: {whiteSpace: 'nowrap'} },
  { columnKey: 'description', content: 'Description' },
  { columnKey: 'total', content: 'Total', isSortable: true, isDesc: true, style: { textAlign: 'right' } },
  { columnKey: 'status', content: 'Status' }
]
*/

<Table
  rows={rows}
  columns={columns}
  selectedRows={new Set([1, 2, 3, 5, 11, 22])}
  selectRow={row => console.log('You tried to select row: ' + row)}
  clickRow={row => console.log('You clicked row: ' + row)}
  deleteRow={row => console.log('You tried to delete row: ' + row)}
  hasActions
  sortTable={columnKey => console.log('You tried to sort on column: ' + columnKey)}
  selectAllRows={() => console.log('You tried to select all the rows.')}
/>
    `,
    render: theme => (
      <Table
        rows={mockdata.rows}
        columns={mockdata.columns}
        selectedRows={new Set([1, 2, 3, 5, 11, 22])}
        selectRow={row => console.log('You tried to select row: ' + row)}
        clickRow={row => console.log('You clicked row: ' + row)}
        deleteRow={row => console.log('You tried to delete row: ' + row)}
        hasActions
        sortTable={columnKey => console.log('You tried to sort on column: ' + columnKey)}
        selectAllRows={() => console.log('You tried to select all the rows.')}
        theme={theme}
      />
    )
  },
  category: CATEGORY.TABLES,
  path: 'components/Core/Table/Table'
})

export default Table
