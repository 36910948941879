import { MouseEventHandler, ReactNode, memo, useRef } from 'react'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import {
  FloatingArrow,
  FloatingFocusManager,
  Placement,
  arrow,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  useRole,
  useTransitionStyles
} from '@floating-ui/react'
import cn, { Argument } from 'classnames'
import isEqual from 'lodash/isEqual'

import { Portal, Tooltip, useWithPopper } from 'simple-core-ui'
import s from './Popover.scss'

type OptionalProps = Partial<{
  trigger: ReactNode
  triggerClassName: Argument
  contentClassName: Argument
  placement: Placement
  testId: string
  hasArrow: boolean
  offset: number
  border: boolean
  borderColor: string
  borderWidth: number
  stayOpenOnClick: boolean
  id?: string
}>

interface Action {
  name: string
  text: string | ReactNode
  visible?: boolean
  disabled?: boolean
  tip?: string
  onClick: MouseEventHandler
}

type PopoverWithActionsProps = {
  actions: Action[]
  content?: never
}

type PopoverWithContentProps = {
  content: ReactNode
  actions?: never
}

type DiscriminatedUnionProps = PopoverWithActionsProps | PopoverWithContentProps

type Props = DiscriminatedUnionProps & OptionalProps

const Popover = memo<Props>(
  ({
    trigger,
    triggerClassName,
    content,
    actions,
    contentClassName,
    placement = 'bottom',
    testId,
    hasArrow = true,
    offset: offsetValue = 10,
    border,
    borderColor = '#1d1d1d',
    borderWidth = 1,
    stayOpenOnClick,
    id
  }) => {
    const arrowRef = useRef(null)

    const middleware = [offset(offsetValue), flip(), shift()]
    if (hasArrow) {
      middleware.push(
        arrow({
          element: arrowRef
        })
      )
    }

    const { floatingStyles, setReference, setFloating, context, togglePopper } = useWithPopper({
      middleware,
      placement,
      handleState: true
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

    const transition = useTransitionStyles(context, {
      initial: { opacity: 0 },
      open: { opacity: 1 }
    })

    return (
      <>
        <span
          id={id}
          data-testid={`${testId}-trigger`}
          ref={setReference}
          {...getReferenceProps({
            className: cn(triggerClassName),
            onClick: e => {
              e.stopPropagation()
            }
          })}
        >
          {trigger || <IoIosInformationCircleOutline />}
        </span>
        {(actions || content) && (
          <Portal>
            {transition.isMounted && (
              <FloatingFocusManager context={context} modal={false}>
                <div
                  data-testid={`${testId}-content`}
                  ref={setFloating}
                  style={{
                    ...transition.styles,
                    ...floatingStyles,
                    ...(border ? { borderColor, borderWidth } : {})
                  }}
                  {...getFloatingProps({
                    className: cn(
                      s.content,
                      {
                        [s.text]: typeof content === 'string',
                        [s.border]: border
                      },
                      contentClassName
                    ),
                    onClick() {
                      if (!stayOpenOnClick) togglePopper()
                    }
                  })}
                >
                  {hasArrow && (
                    <FloatingArrow
                      ref={arrowRef}
                      context={context}
                      fill="#FFF"
                      stroke={borderColor}
                      strokeWidth={border ? borderWidth : 0}
                    />
                  )}
                  {actions ? (
                    <ul>
                      {(actions as Action[]).map(
                        ({ name, text, visible = true, disabled, tip, onClick }) => {
                          if (!visible) return null

                          return (
                            <Tooltip
                              key={name}
                              trigger={
                                <li
                                  className={cn(s.action, { [s.disabled]: disabled })}
                                  onClick={e => {
                                    e.preventDefault()
                                    onClick(e)
                                  }}
                                >
                                  {text}
                                </li>
                              }
                              content={tip}
                            />
                          )
                        }
                      )}
                    </ul>
                  ) : (
                    content
                  )}
                </div>
              </FloatingFocusManager>
            )}
          </Portal>
        )}
      </>
    )
  },
  (prevProps, nextProps) => {
    let areEqual = true
    for (const key in nextProps) {
      if (key === 'content') continue
      if (
        !(key in prevProps) ||
        !isEqual(prevProps[key as keyof Props], nextProps[key as keyof Props])
      ) {
        areEqual = false
        break
      }
    }
    return areEqual
  }
)

export default Popover
