import { CSSProperties, useContext, ReactNode } from 'react'
import s from './Stepper.scss'
import cn from 'classnames'
import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'
import { FaCheckCircle } from 'react-icons/fa'
import SimpleContext from 'simple-core-ui/context/SimpleContext'

interface Props {
  automaticHeader?: boolean
  headerText?: string
  headerStyle?: CSSProperties
  steps: Step[]
  activeStep: number
  separatorStyle?: CSSProperties
  displayIndex?: boolean
  orientation?: 'horizontal' | 'vertical'
  hasNewDesign?: boolean
  textStyle?: CSSProperties
}

interface Step {
  text: ReactNode
  onClick?: () => void
  style?: CSSProperties
  content?: ReactNode
  completed?: boolean
}

const Stepper = ({
  automaticHeader,
  headerText,
  headerStyle,
  steps,
  activeStep = 0,
  separatorStyle,
  displayIndex,
  orientation = 'horizontal',
  hasNewDesign = false,
  textStyle
}: Props) => {
  const { theme } = useContext(SimpleContext)

  const isCg = theme === 'cg'

  if (hasNewDesign) {
    return (
      <div className={s.container}>
        <div className={cn(s.flex, s.newDesign)}>
          {steps.map((step, i) => (
            <div
              key={i}
              className={cn(s.stepWrapper, {
                [s.active]: activeStep - 1 === i,
                [s.completed]: i < activeStep - 1,
                [s.cgActive]: isCg
              })}
              style={step.style}
            >
              <div
                className={cn(s.flex, s.step, {
                  [s.first]: i === 0,
                  [s.last]: i === steps.length - 1
                })}
              >
                <div
                  className={cn(s.circle, {
                    [s.cgCircle]: isCg
                  })}
                >
                  {displayIndex ? `${i + 1}` : ''}
                </div>
                <p
                  onClick={step.onClick}
                  className={cn(s.text, {
                    [s.actionable]: step.onClick,
                    [s.cgText]: isCg,
                    [s.textStyle]: textStyle
                  })}
                >
                  {step.text}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className={cn(s.separator, s.newDesign)} style={separatorStyle} />
      </div>
    )
  }

  if (orientation === 'vertical') {
    return (
      <div className={s.wrapper}>
        {steps.map((step, i) => (
          <div key={i} className={s.verticalStepWrapper} style={step.style}>
            <div className={s.circle}>
              {step.completed && <FaCheckCircle className={s.check} />}
            </div>
            <h3 className={s.text} onClick={() => step.onClick && step.onClick()}>
              {displayIndex ? `${i + 1}. ` : ''} {step.text}
            </h3>
            <div className={s.contentWrapper}>{step.content}</div>
          </div>
        ))}
      </div>
    )
  }
  return (
    <div>
      {(automaticHeader || headerText) && (
        <div className={s.header} style={headerStyle}>
          {automaticHeader ? steps[activeStep - 1]?.text : headerText ? headerText : ''}
        </div>
      )}
      <div className={s.flex}>
        {steps.map((step, i) => (
          <div
            key={i}
            className={cn(s.stepWrapper, {
              [s.active]: activeStep - 1 === i,
              [s.completed]: step.completed || i < activeStep - 1,
              [s.cgActive]: isCg
            })}
            style={step.style}
          >
            <div className={cn(s.flex, s.step)}>
              <div className={cn(s.circle, { [s.cgCircle]: isCg })}>
                {displayIndex ? `${i + 1}` : ''}
              </div>
              <p
                onClick={step.onClick}
                className={cn(s.text, { [s.actionable]: step.onClick, [s.cgText]: isCg })}
              >
                {step.text}
              </p>
            </div>
            {i < steps.length - 1 && (
              <div className={cn(s.separator, { [s.cgSep]: isCg })} style={separatorStyle} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

registration.register({
  name: 'Stepper',
  description: 'Horizontal stepper',
  props: [
    {
      name: 'automaticHeader',
      type: 'boolean',
      optional: true,
      note:
        'Horizontal only. If present, a title will show above the stepper. The text will automatically be the active step\'s "text" property'
    },
    {
      name: 'headerText',
      type: 'string',
      optional: true,
      note:
        'Horizontal only. The text to be shown as header. If "automaticHeader" is present then "headerText" will be ignored'
    },
    {
      name: 'headerStyle',
      type: 'object',
      optional: true,
      note:
        'Horizontal only. The styles to be applied to header text. "automaticHeader" or "headerText" must be present'
    },
    {
      name: 'steps',
      type: 'array',
      optional: false,
      note:
        'Horizontal: Array of steps. E.g [{ text: "Step1", onClick: () => {}, href: "", style: {} }]'
    },
    {
      name: 'activeStep',
      type: 'number',
      optional: false,
      note: 'Horizontal only. Currently active step'
    },
    {
      name: 'hasNewDesign',
      type: 'boolean',
      optional: true,
      note: 'If the stepper has the new design'
    },
    {
      name: 'separatorStyle',
      type: 'object',
      optional: true,
      note: 'Horizontal only. The styles to be applied to the lines between steps'
    },
    {
      name: 'displayIndex',
      type: 'boolean',
      optional: true,
      note: 'If indexes are displayed before step text'
    },
    {
      name: 'orientation',
      type: 'string',
      optional: true,
      note: 'Stepper orientation. Default is horizontal'
    }
  ],
  example: {
    literal: `
      <section style={{ width: '700px',  margin: '0 auto' }}>
        <Stepper
          automaticHeader
          activeStep={2}
          displayIndex
          steps={[
            {
              text: 'Create Relationship'
            },
            {
              text: 'Pair Level 1/Level 2'
            },
            {
              text: 'Pair Level 2/Level 3'
            },
            {
              text: 'Activate'
            }
          ]}
        />
      </section>
    `,
    render: () => (
      <section style={{ width: '700px', margin: '0 auto' }}>
        <Stepper
          automaticHeader
          activeStep={2}
          displayIndex
          steps={[
            {
              text: 'Create Relationship'
            },
            {
              text: 'Pair Level 1/Level 2'
            },
            {
              text: 'Pair Level 2/Level 3'
            },
            {
              text: 'Activate'
            }
          ]}
        />
      </section>
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/Stepper/Stepper'
})

export default Stepper
