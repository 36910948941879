import { useEffect } from 'react'

const useFocusInput = (focused, ref) => {
  useEffect(() => {
    if (ref && ref.current) {
      if (focused) {
        setTimeout(() => {
          if (ref.current?.value) {
            ref.current.select()
          } else {
            ref.current.focus()
          }
        }, 200)
      } else {
        ref.current.blur()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused])
}

export default useFocusInput
