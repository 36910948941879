import classNames from 'classnames'

import s from './CgPanel.scss'

import { ErrorBoundaryContainer, If } from 'simple-core-ui'

const CgPanel = ({
  title,
  children,
  actions = [],
  hasInnerPadding = true,
  isError,
  isBodyOnly,
  panelStyles = {},
  innerStyles = {}
}) => {
  const mainCss = {
    padding: hasInnerPadding ? '15px 20px' : '',
    ...innerStyles
  }

  return (
    <section
      className={classNames(s.container, { [s.isBodyOnly]: isBodyOnly })}
      style={panelStyles}
    >
      <If condition={!isBodyOnly}>
        <header className={s.header}>
          {typeof title === 'string' ? <h2>{title}</h2> : <span>{title}</span>}
          <span className={s.actions}>{actions.length > 0 && actions.map(el => el)}</span>
        </header>
      </If>
      <main className={classNames(s.main, { [s.isBodyOnly]: isBodyOnly })} style={mainCss}>
        <ErrorBoundaryContainer isError={isError}>
          {(isError, message) => (isError ? message : children)}
        </ErrorBoundaryContainer>
      </main>
    </section>
  )
}

export default CgPanel
