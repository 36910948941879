import { useState, useMemo } from 'react'
import get from 'lodash/get'
import cn from 'classnames'
import TableRowActions from './TableRowActions'
import TableRowCheckbox from './TableRowCheckbox'
import TableRowAvatar from './TableRowAvatar'
import TableRowBorder from './TableRowBorder'
import TableRowExpandIcon from './TableRowExpandIcon'
import s from '../Table.scss'

const TableRow = ({
  row,
  columns,
  clickRow,
  isChecked,
  selectRow,
  avatar,
  hasActions,
  noHover,
  actions = [],
  borderColor,
  borderPosition,
  deleteRow,
  editRow,
  downloadRow,
  customAction,
  renderCell,
  theme,
  checkboxSize,
  rowIndex,
  showCheckbox,
  alwaysShowActions,
  disableActionsEventPropagation,
  memoizedRowActions,
  customActionsMemoizationDeps,
  hasCgOutlineCheckbox
}) => {
  const getSelectedRowStyles = (isChecked, theme) => {
    return isChecked
      ? {
          row: {
            fontWeight: '400',
            color: 'initial',
            backgroundColor: '#EDF6FF'
          }
        }
      : {}
  }

  const selectedRowStyles = getSelectedRowStyles(isChecked, theme)
  const [expanded, setExpanded] = useState(false)

  const keyIntoColumn = (columns, columnKey) => {
    const column = columns.find(column => column.columnKey === columnKey)

    if (!column) {
      console.error(`Column Key: ${columnKey} not found in columns: ${columns}`)
    }

    return column
  }

  const TableRowsActionsMemo = useMemo(
    () => (
      <TableRowActions
        downloadRow={downloadRow}
        row={row}
        deleteRow={deleteRow}
        editRow={editRow}
        customAction={customAction}
        actions={actions}
        alwaysShowActions={alwaysShowActions}
        disableActionsEventPropagation={disableActionsEventPropagation}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downloadRow, row.id, deleteRow, editRow, ...customActionsMemoizationDeps]
  )

  return (
    <>
      <tr
        data-testid={`table-row-${rowIndex}`}
        className={cn(
          clickRow ? s.clickableRow : s.row,
          row.expandableContent && (rowIndex % 2 ? s.expandableOdd : s.expandableEven),
          { [s.borderLeft]: borderColor && borderPosition === 'left', [s.noHover]: noHover }
        )}
        style={selectedRowStyles.row}
        onClick={() => {
          if (clickRow) {
            clickRow()
          }
        }}
      >
        {selectRow && (
          <TableRowCheckbox
            theme={theme}
            selectRow={selectRow}
            isChecked={isChecked}
            size={checkboxSize}
            isSelectable={row.isSelectable}
            showCheckbox={showCheckbox}
            isCgOutline={hasCgOutlineCheckbox}
          />
        )}
        {row.expandableContent && (
          <TableRowExpandIcon
            expanded={expanded}
            expandRow={value => setExpanded(value)}
            isDisabled={row.expandableContent.isDisabled}
          />
        )}

        {avatar && <TableRowAvatar person={avatar} styles={selectedRowStyles.avatar} />}
        {row.cells.map((cell, idx) => {
          const column = keyIntoColumn(columns, cell.columnKey)

          if (!column) {
            return <td key={idx} />
          }

          const columnStyle = get(column, 'style', {})

          if (row.is_sub_title) {
            return idx === 0 ? (
              <td style={{ ...columnStyle }} key={idx}>
                {row.is_sub_title.text}
              </td>
            ) : (
              <td style={{ ...columnStyle }} key={idx} />
            )
          }

          return (
            <td
              data-testid={cell.columnKey}
              key={idx}
              style={{ ...columnStyle }}
              colSpan={cell.colSpan || 1}
            >
              {renderCell
                ? renderCell({ rowId: row.id, ...cell })
                : get(column, 'render')
                ? column.render(cell, row, rowIndex, column)
                : cell.content}
            </td>
          )
        })}
        {(hasActions || !!actions.length) &&
          (memoizedRowActions ? (
            TableRowsActionsMemo
          ) : (
            <TableRowActions
              downloadRow={downloadRow}
              row={row}
              deleteRow={deleteRow}
              editRow={editRow}
              customAction={customAction}
              actions={actions}
              alwaysShowActions={alwaysShowActions}
              disableActionsEventPropagation={disableActionsEventPropagation}
            />
          ))}
        {borderColor && (!borderPosition || borderPosition === 'right') && (
          <TableRowBorder color={borderColor} />
        )}
      </tr>
      {row.expandableContent && expanded && (
        <tr className={cn(s.expandableContent, rowIndex % 2 ? s.expandableOdd : s.expandableEven)}>
          <td colSpan={row.cells.length + 1}>{row.expandableContent.content}</td>
        </tr>
      )}
    </>
  )
}

export default TableRow
